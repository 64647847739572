<template>
  <div class="container">
    <!-- ACTIONS -->
    <div class="text-right mb-3">
      <router-link
          :to="{name: 'team.create'}">
        <a-button
            type="primary"
            class="font-weight-bold"
            icon="plus">
          {{$t('BUTTON.create')}}
        </a-button>
      </router-link>
    </div>

    <!--PAGINATION-->
    <Pagination :show-size-changer="false"
                :total="pagination.total"
                :current-page="pagination.currentPage"
                :page-size="pagination.perPage"
                @changePage="changePage"
                class="text-right mb-3"/>

    <!-- TABLE -->
    <a-table :columns="columns"
             :data-source="list"
             :locale="{emptyText: $t('COMMON.empty_data')}"
             :pagination="false"
             rowKey="id"
             bordered>
      <!--image-->
      <template slot="image"
                slot-scope="record">
        <img :src="record.images[0].url | imageEmpty"
             alt="pic"
             loading="lazy"
             style="border-radius: 50%; aspect-ratio: 1;">
      </template>

      <!--date-->
      <template slot="position"
                slot-scope="text">
        <span v-html="text"/>
      </template>
      <!--date-->
      <template slot="date"
                slot-scope="record">
        <div class="mb-1">
          <p v-text="$t('COMMON.created_at')+':'" class="mb-0 font-weight-bold" style="font-size: 12px"/>
          <span>{{ record.created_at | formatDate() }}</span>
        </div>
        <div>
          <p v-text="$t('COMMON.updated_at')+':'" class="mb-0 font-weight-bold" style="font-size: 12px"/>
          <span>{{ record.updated_at | formatDate() }}</span>
        </div>
      </template>

      <!--status-->
      <template slot="status"
                slot-scope="text, record">
        <a-tag v-if="'is_home_page' in record.additional && record.additional.is_home_page"
               color="red"
               class="mb-2">
          {{ $t('TEAM.status_display_home') }}
        </a-tag>
        <a-tag :color="text ? '#87d068' : '#BBBBBB'"
               class="font-weight-bold mr-0">
          {{ text ? $t('COMMON.status_enabled') : $t('COMMON.status_disabled')}}
        </a-tag>
      </template>

      <!--Action-->
      <template slot="action"
                slot-scope="record">
        <a-tooltip placement="topRight">
          <template slot="title">
            <span>{{ `${URL_CLIENT}/doi-ngu/${record.slug}` }}</span>
          </template>
          <a-button @click.prevent="copyClipboard(`${URL_CLIENT}/doi-ngu/${record.slug}`)"
                    icon="link"
                    size="small"
                    class="mr-1"/>
        </a-tooltip>

        <router-link :to="{ name: 'team.edit', params: {id: record.id} }"
                     custom
                     v-slot="{ navigate }">
          <a-button @click="navigate"
                    icon="edit"
                    size="small"
                    type="primary" class="mr-1"/>
        </router-link>

        <a-popconfirm :title="$t('NOTIFICATION.msg_delete_confirm')"
                      :ok-text="$t('BUTTON.delete')"
                      :cancel-text="$t('BUTTON.back')"
                      placement="topLeft"
                      @confirm="deleteRecord(record.id)">
          <a-button type="danger"
                    size="small"
                    icon="delete"/>
        </a-popconfirm>
      </template>
    </a-table>

    <!--PAGINATION-->
    <Pagination :show-size-changer="false"
                :total="pagination.total"
                :current-page="pagination.currentPage"
                :page-size="pagination.perPage"
                @changePage="changePage"
                class="text-right mt-3" />

    <!-- ACTIONS -->
    <div class="text-right mt-3">
      <router-link
          :to="{name: 'team.create'}">
        <a-button
            type="primary"
            class="font-weight-bold"
            icon="plus">
          {{$t('BUTTON.create')}}
        </a-button>
      </router-link>
    </div>
  </div>
</template>

<script>
// Store
import store from '@/store'
import { mapActions, mapState } from 'vuex'
// Components
import Pagination from '@/components/Pagination'
import FormMixin from '@/mixins/form.mixin'

export default {
  name: 'Index',

  components: { Pagination },

  mixins: [FormMixin],

  data () {
    return {
      params: {
        page: 1,
        perPage: 10,
        'filters[type]': 'team'
      },
      URL_CLIENT: process.env.VUE_APP_URL_CLIENT
    }
  },

  beforeRouteEnter (to, from, next) {
    const params = {
      page: 1,
      perPage: 10,
      'filters[type]': 'team'
    }
    store.dispatch('postTeam/getList', params).then(_ => next())
  },

  computed: {
    // State
    ...mapState('postTeam', ['list', 'pagination']),

    columns () {
      return [
        {
          title: this.$t('COMMON.image'),
          scopedSlots: { customRender: 'image' },
          width: '100px'
        },
        {
          title: this.$t('COMMON.name'),
          dataIndex: 'title'
        },
        {
          title: this.$t('COMMON.position'),
          dataIndex: 'description',
          scopedSlots: { customRender: 'position' }
        },
        {
          title: this.$t('COMMON.date'),
          width: 160,
          scopedSlots: { customRender: 'date' }
        },
        {
          title: this.$t('COMMON.status'),
          dataIndex: 'is_published',
          width: 140,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: this.$t('COMMON.action'),
          align: 'center',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },

  methods: {
    // Action
    ...mapActions('postTeam', ['getList', 'removeTeam']),

    changePage (num) {
      this.params = {
        ...this.params,
        page: num
      }
      this.fetchList(this.params)
    },

    deleteRecord (id) {
      this.removeTeam(id).then(result => {
        if (result) {
          this.onSuccess(this.$t('NOTIFICATION.title_completion'), this.$t('NOTIFICATION.msg_delete_success'))
          this.fetchList(this.params)
        } else {
          this.onSuccess(this.$t('NOTIFICATION.title_fail'), this.$t('NOTIFICATION.msg_delete_fail'))
        }
      })
    },

    copyClipboard (link) {
      navigator.clipboard.writeText(link)
    },

    // FETCH
    fetchList (params = {}) {
      this.getList(params)
    }
  }
}
</script>
